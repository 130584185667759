import Nav from './Nav';
import Button from './Button';
import React, { useEffect } from 'react';

export default function Main() {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div id="main">
      <Nav />
      <section id="intro" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <br></br>
              <h2>About Aweigh</h2>
            </header>
            <p>
              Aweigh is a mobile App that has been built with public safety and
              care for UK's waterways in mind.
            </p>
            <p>
              Thousands of people enjoy the UK waterways throughout the year.
              Aweigh is here to support the public when help is needed, whether
              for health and safety or to report incidents in these beautiful
              environments.
            </p>
            <ul className="actions">
              <li>
                <Button text="More Info" route="more_info" classes="button" />
              </li>
            </ul>
          </div>
          <span className="image">
            <img src="images/pic01.jpg" alt="" />
          </span>
        </div>
      </section>

      <section id="first" className="main special">
        <header className="major">
          <h2>The Aweigh App</h2>
        </header>
        <ul className="features">
          <li>
            <span className="icon major style1 fa-android" />
            <h3>Android</h3>
            <p>
              Available on the Play Store{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.aweigh"
                target="_blank"
                rel="noopener noreferrer"
              >
                here!
              </a>
            </p>
          </li>
          <li>
            <span className="icon major style3 fa-apple" />
            <h3>Apple</h3>
            <p>
              Available on the Apple Store{' '}
              <a
                href="https://itunes.apple.com/gb/app/aweigh/id1460562069?mt=8&ign-mpt=uo%3D2"
                target="_blank"
                rel="noopener noreferrer"
              >
                here!
              </a>
            </p>
          </li>
        </ul>
      </section>

      <section id="cta" className="main special">
        <header className="major">
          <h2>Privacy</h2>
          <p>
            At Aweigh we take users privacy seriously, read more about our
            privacy statements here.
          </p>
          <ul className="actions special">
            <li>
              <Button
                text="Privacy Statement Mobile"
                route="mobile_privacy"
                classes="button primary"
              />
            </li>
          </ul>
        </header>
      </section>
      <section id="contact" className="main special">
        <header className="major">
          <h2>Contact</h2>
          <ul className="actions special">
            <li>
              <p>Want to talk to us about our App?</p>
            </li>
            <li>
              <p>Got ideas on how we can improve?</p>
            </li>
          </ul>
          <p>We'd love to hear from you.</p>
          <div>
            <span className="icon major style1 fa-envelope" />
          </div>
          <Button
            text="Email us"
            classes="button primary"
            email="contactus@aweigh.co.uk"
          />
        </header>
      </section>
    </div>
  );
}
