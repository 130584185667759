import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  classes: string;
  route?: string;
  text: string;
  email?: string;
}

const Button = ({ classes, route, text, email }: Props) => {
  if (route) {
    return (
      <li>
        <Link to={route} className={classes}>
          {text}
        </Link>
      </li>
    );
  }

  return (
    <a href={`mailto:${email}`} className={classes}>
      {text}
    </a>
  );
};

export default Button;
