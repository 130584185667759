import React, { useEffect } from 'react';

import HomeNav from './HomeNavBusiness';
import Button from '../../components/Button';

const Business = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div id="main">
      <HomeNav />
      <section id="aweighLocal" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <br />
              <h2>Aweigh for Business</h2>
            </header>
            <p>
              With over eleven thousand downloads <i>(as of oct 2020)</i>, and the app being used by someone every minute, Aweigh ensures your business will find itself
              at the fingertips of your target customer.
            </p>
            <p>
              Working closely with local authorities and with support from
              boatyards, thousands of visitors and residents are encouraged to
              download the Aweigh app free of charge each year. Unlike a
              website, the convenience of Aweigh provides users with instant
              local information whilst on and around the rivers, without the
              need to search for it.
            </p>
          </div>
          <span className="image">
            <img src="images/app_in_use_business2.png" alt="" />
          </span>
        </div>
      </section>
      <section id="how" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <h2>How it works</h2>
            </header>
            <p>
              Using GPS location services, the Aweigh App displays the business
              information of our subscribers, based on current location or
              alternatively by selecting a desired destination.
            </p>
            <p>
              Businesses are sorted into 4 categories.{' '}
              <b>Food &amp; Drink, Shopping, Leisure</b> and{' '}
              <b> Services.&nbsp;&nbsp;</b>
              By selecting the relevant category, Aweigh users can see
              businesses relevant to their needs.
            </p>
            <p>
              Choose the correct category for your business or choose to display
              in multiple categories.
            </p>
          </div>
          <span className="image">
            <img src="images/explore.gif" alt="" />
          </span>
        </div>
        <div className="spotlight">
          <div className="content">
            <p>
              A Premium business subscription with Aweigh has the benefit of
              bringing critical key information to your customers, including:
              <b>
                <li>Company Logo and Name</li>
                <li>Company Biography</li>
                <li>Opening Times</li>
                <li>Links to your Website</li>
                <li>Direct access to your social media channels</li>
                <li>Map Location</li>
                <li>Phone Numbers</li>
                <li>Email</li>
                <li>Address</li>
              </b>
              Our unique way of categorising your businesses allows Aweigh users
              to find the business they need where and when they need it.
            </p>
          </div>
          <span className="image">
            <img src="images/explore_map.png" alt="" />
          </span>
        </div>
      </section>
      <section id="list" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <h2>
                Premium Subscription<br></br>
              </h2>
            </header>
            <p>
              Annual subscription to the aweigh app costs:-
              <li>
                Usually &pound;200 + VAT per year
              
              </li>
              </p><p>
                <b>*** Special Offer 2021 ***</b>
              </p>
              <p>
              <li> 
                Just <b>&pound;100</b> + VAT
              </li>
            </p>
            <p>
              Register your interest and a member of the Aweigh team will
              contact you.
            </p>
            <Button
              text="Business Subscription"
              classes="button primary"
              email="contactus@aweigh.co.uk?subject=Aweigh%20App%20-%20Explore%20Subscription%20Enquiry&body=Please%20enter%20some%20details%20about%20your%20business%20here"
            />
          </div>
          <span className="image">
            <img src="images/aweigh_ltd.png" alt="" />
          </span>
        </div>
      </section>
      <section id="boats" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <br />
              <h2>Boat Yards Supporting Aweigh</h2>
            </header>
            <div className="flexThing">
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FBarnesBrinkCraft.png?alt=media&token=11dd33cd-7d26-4ba9-b05e-b2cf604d76d3" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FEastwoodWhelpton.png?alt=media&token=646ba63f-7c73-460d-b0c5-60a23787d0c5" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FHerbertWoods.png?alt=media&token=c48bda10-fdf6-484d-8b6f-bb20aee8106b" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FRichardsons3.png?alt=media&token=7e04096b-1803-466d-81d5-7685d56596b6" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FBroomboats.jpg?alt=media&token=8ea305a0-0a92-419c-bed2-a9907d96d375" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FNorfolkBroadsDirect.png?alt=media&token=98f7de49-2940-4755-b6c1-12c7af6afd44" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FMarthamBoats.png?alt=media&token=689321fc-ed3e-4af5-98ce-b4fe33a54086" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FHuntersYard.png?alt=media&token=1632dd16-6f8c-47db-b15d-5b0a46c0b25f" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FJbBoatSales.png?alt=media&token=beef6613-fcaa-4ad5-836f-3eac1ce2a7e8" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FHorningFerryMarina.png?alt=media&token=32613326-cd19-4028-aa14-8ec9dacfc160" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FMarthamFerryBoatyard.png?alt=media&token=5253eb02-7ee2-4e88-bb56-3174f27a4860" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FBroadsBoatingCompany.png?alt=media&token=912906b7-fc20-4e9a-884f-1434e19e87d4" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FSilverline.png?alt=media&token=b334cfbc-ecb7-4d25-b7c4-90862f4a7f75" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2Fboat_100x100.png?alt=media&token=20ee128f-e9a3-4589-a0e0-af429b3e84ca" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FFreedomBoats.png?alt=media&token=70a9f86f-b254-4673-a888-44e985aae30f" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FMaycraft.png?alt=media&token=59ac1bdb-5c35-402f-81e4-2032e535a999" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FPhoenix.png?alt=media&token=19eca279-9636-4c90-8a6d-aaf616a2fe00" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FWayford.png?alt=media&token=fd0e2932-f6d8-46ae-8d7d-bf37877bd037" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2FWaveneyRiverCentre.png?alt=media&token=c31491bb-a195-4b58-8f5d-17790e99f4b7" />
              <img src="https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2Fhoseasons.png?alt=media&token=2e87e965-9be7-4219-8601-8e393e458bde" />   
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Business;
