import React from 'react';

const Header = () => (
  <header id="header" className="alt">
    <span className="logo">
      <img src="images/boat_white.png" alt="" />
    </span>
    <h1>Aweigh</h1>
    <p>The essential Waterways App</p>
  </header>
);

export default Header;
