import React, { useEffect } from 'react';
import HomeNav from './HomeNav';

const More_Info = () => {
  useEffect(() => window.scrollTo(0, 600));
  return (
    <div id="main">
      <HomeNav />
      <section id="content" className="main">
        <span className="image main">
          <img src="images/pic04.jpg" alt="" />
        </span>
        <h2>More about Aweigh</h2>
        <p>
          Our app has been built initially for use on and around the Norfolk
          Broads with a view to rolling it out for beneficial use on the rest of
          UK's waterways and river networks.
        </p>
        <p>
          Split into 4 sections, it is designed to provide the user with
          valuable information whilst on and around the water.
        </p>
        <h2>Mapping and Speed</h2>
        <p>
          The mapping element of the application shows the user their current
          location and speed they are traveling. This can be especially useful
          for users that are unsure of their speed and can help protect the
          local wildlife and avoid a large fine of thousands of pounds.
        </p>
        <h2>Tides</h2>
        <p>
          <b>Tide times</b> - Is the tide rising or falling? when is high tide?
          Tide times and heights will give you up to date information. knowing
          the flow of the river can really help when mooring up or pulling away!
        </p>
        <p>
          <b>Sun Phases</b> - Know when the sunrises or when it is going to set.
          Find those moorings and allow enough time to travel to your desired
          destination.
        </p>
        <h2>Help</h2>
        <p>
          Crucial numbers in case of emergency. The Help page gives users one
          click call options for the emergency services, broads authority or
          Environment agency. Report incidents or call for help.
        </p>
        <p>
          Use what3words to pinpoint your location. Get emergency services to
          your location quicker. Pinpoint locations when reporting incidents or
          passing advice to the emergency services.
        </p>
        <p>
          See your current Longitude and Latitude values to pass to the
          emergency services to pinpoint your location.
        </p>
        <h2>Menu</h2>
        <p>
          Check bridge heights, buy fishing licences, find local waste
          facilities and keep up to date with urgent boating news.
        </p>
      </section>
    </div>
  );
};

export default More_Info;
