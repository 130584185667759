import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';

import Main from './components/Main';
import Mobile_Privacy from './components/Mobile_Privacy';
import Business from './Business/components/Business';
import Web_Privacy from './components/Web_Privacy';
import More_Info from './components/More_Info';
import Header from './components/Header';
import Places from './Business/components/Business';
import BusinessForm from './Business/components/BusinessForm';

export default function App() {
  return (
    <div id="wrapper">
      <Header />
      <Router>
        <Route path="/" exact component={Main} />
        <Route path="/home/" component={Main} />
        <Route path="/mobile_privacy/" component={Mobile_Privacy} />
        <Route path="/business/" component={Business} />
        <Route path="/web_privacy/" component={Web_Privacy} />
        <Route path="/more_info/" component={More_Info} />
        <Route path="/businessForm/" component={BusinessForm} />
      </Router>
    </div>
  );
}
