import React, { useEffect, useState } from 'react';

export default function BusinessForm() {
  const postUrl =
    'https://europe-west2-aweigh-87620.cloudfunctions.net/SetNewBusinessApp';

  const [state, setState] = useState<
    Record<string, { value: string; label: string }>
  >({
    name: {
      label: 'Business Name',
      value: 'Aweigh',
    },
    description: {
      label: 'Description',
      value: 'hjhkhkjhkhkjhkjh',
    },
    place: {
      label: 'Place',
      value: 'Acle',
    },
    address1: {
      label: 'Address',
      value: 'ddd',
    },
    address2: {
      label: '',
      value: 'fff',
    },
    address3: {
      label: '',
      value: 'ggg',
    },
    postcode: {
      label: 'Postcode',
      value: 'mk45 4lw',
    },
    phone: {
      label: 'Phone',
      value: '07977997543',
    },
    website: {
      label: 'Website Address',
      value: 'aweigh.co.uk',
    },
    facebook: {
      label: 'Facebook',
      value: 'fb',
    },
    instagram: {
      label: 'Instagram',
      value: 'ig',
    },
    twitter: {
      label: 'Twitter',
      value: 'tw',
    },
    dogString: {
      label: 'Dogs Welcome?',
      value: 'Dogs Welcome!!',
    },
    foodDrink: {
      label: 'Business Type Food & Drink',
      value: 'true',
    },
    shopping: {
      label: 'Business Type Shopping',
      value: 'true',
    },
    leisure: {
      label: 'Business Type Leisure',
      value: 'true',
    },
    services: {
      label: 'Business Type Services',
      value: 'true',
    },
    openSun: {
      label: 'Opening Times: Sunday',
      value: '1',
    },
    openMon: {
      label: 'Monday',
      value: '2',
    },
    openTue: {
      label: 'Tuesday',
      value: '3',
    },
    openWed: {
      label: 'Wednesday',
      value: '4',
    },
    openThu: {
      label: 'Thursday',
      value: '5',
    },
    openFri: {
      label: 'Friday',
      value: '6',
    },
    openSat: {
      label: 'Saturday',
      value: '7',
    },
    longitude: {
      label: 'Longitude',
      value: '1.456',
    },
    latitude: {
      label: 'Latitude',
      value: '52.789',
    },
    logoURL: {
      label: 'Logo',
      value:
        'https://firebasestorage.googleapis.com/v0/b/aweigh-87620.appspot.com/o/companyLogos%2Fboat_100x100.png?alt=media&token=20ee128f-e9a3-4589-a0e0-af429b3e84ca',
    },
  });
  useEffect(() => window.scrollTo(0, 600), []);

  function updateState(e: any) {
    const val = state[e.target.name];

    const newValue = { ...val, value: e.target.value };
    setState({ ...state, [e.target.name]: { ...newValue } });
  }

  function onSubmit() {
    console.log(JSON.stringify(state));
    postData(postUrl, JSON.stringify(state));
  }

  return (
    <div id="main">
      <section id="aweighLocal" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <br />
              <h2>FORM DATA</h2>
              {Object.keys(state).map(key => {
                //const [checked, setChecked] = useState(false);
                const value = state[key];
                return (
                  <>
                    <label>{value.label}</label>
                    {key === 'description' ? (
                      <textarea
                        name={key}
                        value={value.value}
                        onChange={updateState}
                      />
                    ) : key === 'leisure' ? (
                      <input
                        type="checkbox"
                        name={key}
                        value={value.value}
                        onChange={updateState}
                      />
                    ) : (
                      <input
                        name={key}
                        value={value.value}
                        onChange={updateState}
                      />
                    )}
                  </>
                );
              })}
            </header>
            <button onClick={onSubmit}>Submit</button>
          </div>
        </div>
      </section>
    </div>
  );
}

async function postData(url: any, data: any) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: data, // body data type must match "Content-Type" header
  });
  return await response; // parses JSON response into native JavaScript objects
}
