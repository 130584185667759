import React, { useEffect, useState, useMemo } from 'react';
//import { Link } from 'react-scroll';
import { Link as Route } from 'react-router-dom';

const HomeNav = () => {
  const [fromTop, setFromTop] = useState(0);

  const handleScroll = () => {
    setFromTop(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const navStyle = useMemo(() => {
    return fromTop >= 620 ? styles.fixed : undefined;
  }, [fromTop]);

  return (
    <nav id="nav" style={navStyle}>
      <ul>
        <li>
          <li>
            <Route to="home">Home</Route>
          </li>
        </li>
      </ul>
    </nav>
  );
};

const styles = {
  fixed: {
    position: 'fixed' as 'fixed',
    top: 0,
  },
};

export default HomeNav;
